@media screen and (max-width: 450px) {
  .transport-text {
    font-size: calc(2.3em + 0.9vw) !important;
  }

  .navbar-margin-left {
    margin-left: 0 !important;
  }

  .navbar-margin-right {
    margin-right: 0 !important;
  }

  .map-responsive {
    padding-bottom: 80% !important;
  }

  .navbar-expand .navbar-nav {
    flex-direction: column !important;
  }

  a {
    margin: 0 !important;
    padding: 0 !important;
  }

  ul {
    list-style-position: outside !important;
  }

  .clock-icon:before {
    margin-left: 0px !important;
  }

  #title-comapny {
    font-size: calc(3.325rem + 0.9vw) !important;
  }

  #title-comapny-next {
    font-size: 1.8rem !important;
  }
}
@media screen and (max-width:767px) {
  .ciezarowki-miedzynarodowe1 {
    height: 80vh !important;
    width: 85vw !important;
  }
  
  .ciezarowki-miedzynarodowe2 {
    width: 90vw !important;
  } 
  .international-img {
    height: 200px !important;
  } 
}
@media screen and (max-width:991px) and (min-width:768px) {
  /* .img-gallery {
    height:100% !important;
  } */
}
/* Extra large MEDIA */
@media screen and (max-width: 1200px) {
  #title-comapny {
    font-size: 4rem;
    color: wheat;
  }

  #title-comapny-next {
    font-size: 4rem;
    color: wheat;
  }

  .coop-title {
    font-size: 3.5rem !important;
  }
  /* .img-chlodnie {
    width: 400px !important;
    height: 320px !important;
  } */
}
@media screen and (min-width: 1200px) {
  #title-comapny {
    font-size: 4rem;
    color: wheat;
  }

  #title-comapny-next {
    font-size: 4rem;
    color: wheat;
  }

  .coop-title {
    font-size: 3.5rem !important;
  }
}

@media screen and (max-width: 576px) {
  .wrap-site {
    max-width: 100% !important;
    margin-left: 1em !important;
    margin-right: 1rem !important;
    /* margin-right: auto; */
   }
  .transport-text {
    font-size: calc(3.6rem + 0.9vw) !important;
  }
.text-chlodnie {
  margin-left:0rem !important;
}

}

@media screen and (max-width: 456px) {
  .transport-text {
    font-size: calc(2.4rem + 0.9vw) !important;
  }
}

body {
  background-color: black;
  margin: 0px !important;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: 400 !important;
  scroll-behavior: auto;
  font-size: 18px;
}



/*--- Fixed Video Background iOS/Mobile Style--*/
.video-wrap {
  background-attachment: none;
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#video {
  background-attachment: none;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
  z-index: -1000;
}

video {
  background-attachment: none;

  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  transition: 1s opacity;
}

.video-background {
  position: relative;
  width: 100%;
  min-height: 100vh;
  z-index: -1;
  /* filter: brightness(50%) */
}

.caption {
  position: absolute;
  top: 38%;
  z-index: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.phone-icon:before {
  content: "\f095";
  font: var(--fa-font-solid);
  margin-right: 5px;
}

.clock-icon:before {
  content: "\f017";
  font: var(--fa-font-solid);
  margin-right: 5px;
  margin-left: 10px;
}

.facebook-icon:before {
  content: "\f09a";
  font: var(--fa-font-brands);
  /* margin-right: 5px; */
  /* font-size: .8rem; */
}

.linkedin-icon:before {
  content: "\f08c";
  font: var(--fa-font-brands);
  /* margin-left: 5px; */
  /* font-size: .8rem; */
}

a {
  text-decoration: none;
}

a:link {
  color: white; 
}

a:visited {
  color: white;
}

a:focus {
  color: white !important;
}

a:hover {
  color: #ffc107 !important;
}

.py-0 {
  padding-top: 0.01rem !important;
  padding-bottom: 0.01rem !important;
}

.video-background {
  position: relative !important;
  width: 100% !important;
  min-height: 100vh !important;
}

.navbar-absolute {
  position: absolute;
  z-index: 1;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 35px;
}

#myNav {
  width: 100%;
}

#title-comapny {
  font-size: calc(4.325rem + 0.9vw);
  color: wheat;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-shadow: 0.1rem 0.1rem 0.3rem black;
}

#title-comapny-next {
  font-size: calc(2rem + 0.9vw);
  color: wheat;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-shadow: 0.1rem 0.1rem 0.8rem black;
}

#text-after-title {
  font-size: calc(1rem + 0.9vw);
  color: wheat;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-shadow: 0.1rem 0.1rem 0.8rem black;
}



.black-and-white:hover {
  transform: scale(0.9);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.icon-do {
  font-size: 30px;
}

.icon-do:hover {
  transform: scale(0.9);
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.numbers {
  --bs-gutter-x: 8.75rem;
  /* przybliza do siebie kolumny */
}

.font-footer {
  font-size: 0.83rem;
}

.accordion {
  --bs-accordion-color: none !important;
  --bs-accordion-bg: rgb(33, 37, 41) !important;
  --bs-accordion-border-color: none;
  color: white;
}

.accordion-button:not(.collapsed) {
  color: #212529;
  background-color: #ffc107;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: var(--bs-accordion-btn-icon-transform);
}

/* .coop-title{

} */
.form-control {
  background-color: #dddddd !important;
  /* background-color: #212529 !important; */
  border: none !important;
  color: black !important;
}

.form-control:focus {
  outline: rgb(255, 200, 0) solid 1px !important;
}

.form-select {
  background-color: #dddddd !important;
  color: black !important;
  border: none !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus,
.form-control:focus {
  border-color: none;
  box-shadow: none;
  outline: none;
  color: white;
}

.transport-text {
  font-size: calc(4.8rem + 0.9vw);
  font-weight: 800;
  color: white;
  line-height: 90px;
}
.transport-text-de {
  font-size: calc(4.8rem + 0.9vw);
  font-weight: 800;
  color: white;
  line-height: 90px;
}
.text-on-coop {
  color: #f8f9fa;
}

.rmdp-week-day {
  color: #0074d9 !important;
}

.errorInput {
  padding: 3px;
  /* color:red; */
  word-spacing: 1.5px;
  display: none;
  color: red;
}

input:invalid[focused="true"] {
  border: 1px solid red !important;
}

input:invalid[focused="true"] ~ span {
  display: block;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green; */
  /* -webkit-text-fill-color: green; */
  /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #212529;
}

.img-gallery {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 36.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #ff9900;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #ff9900;
}

.form-control::-moz-placeholder {
  color: #212529;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #212529;
}

.form-control::-webkit-input-placeholder {
  color: #212529;
}

.icona {
  height: 50px !important;
  width: 264px !important;
}



.ciezarowki-miedzynarodowe1 {
  height: 60vh;
  width: 37vw;  
}

.ciezarowki-miedzynarodowe2 {
  height: 60vh;
  width: 35vw;
  margin: 0 !important;
}


.img-animation {
  transition: opacity 0.5s ease !important;
}

.wrap-site {
 max-width: 80%;
 margin-left: 4rem;
 margin-right: 4rem;
}
#a-miedzynarodowy:hover {
    color:blue !important;
}

.international-img {
  width:auto;
  height: 400px;
  object-fit: cover;
}
.card-img1 {
    background-image: url("./components/media/daf.webp");
   background-size: cover;
   background-position: 50%;
   height: 400px;
   width:auto;
   position: relative;
}
.card-img2 {
  background-image: url("./components/media/man.webp");
background-size: cover;
background-position: 30%;
height: 400px;
width:auto;
position: relative;
}
.card-img3{
  background-image: url("./components/media/sg_naczepy_chlodnie_firma_marpol500.webp");
background-size: cover;
background-position: 50%;
height: 400px;
width:auto;
position: relative;
}
.card-img4{
  background-image: url("./components/media/st_naczepy_chlodnie_firma_marpol_big.webp");
background-size: cover;
background-position: 10%;
height: 400px;
width:auto;
position: relative;
}
.card-img5{
  background-image: url("./components/media/s2.webp");
background-size: cover;
background-position: 30%;
height: 400px;
width:auto;
position: relative;
}
.card-img6{
  background-image: url("./components/media/s3.webp");
background-size: cover;
background-position: 50%;
height: 400px;
width:auto;
position: relative;
}
.card-img7{
  background-image: url("./components/media/brazowa_ciezarowka1000.webp");
background-size: cover;
background-position: 74%;
height: 400px;
width:auto;
position: relative;
}
.card-img8{
  background-image: url("./components/media/czarna_ciezarowka_marpol750.webp");
background-size:auto;
background-repeat: no-repeat;
background-position: 70%;
height: 400px;
width:auto;
position: relative;
}
.text-zoom {
  -webkit-transform: scale(0.9);
	transform: scale(0.9);
}
.text-zoom:hover {
  -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .4s ease-in-out;
	transition: .4s ease-in-out;
}
.card-img1:hover {
  background-position: 70% !important;
  -webkit-transition: .7s ease-in-out;
	transition: .7s ease-in-out;
}
.card-img2:hover {
  background-position: 70% !important;
  -webkit-transition: .7s ease-in-out;
	transition: .7s ease-in-out;
}
.card-img3:hover {
  
  background-position: 70% !important;
  -webkit-transition: .7s ease-in-out;
	transition: .7s ease-in-out;
}
.card-img4:hover { 
  background-position: 70% !important;
  -webkit-transition: .7s ease-in-out;
	transition: .7s ease-in-out;
}
.card-img5:hover {
  background-position: 70% !important;
  -webkit-transition: .7s ease-in-out;
	transition: .7s ease-in-out;
}
.card-img6:hover {
  background-position: 70% !important;
  -webkit-transition: .7s ease-in-out;
	transition: .7s ease-in-out;
}
.card-img7:hover {
  background-position: 70% !important;
  -webkit-transition: .7s ease-in-out;
	transition: .7s ease-in-out;
}
.card-img8:hover {
  background-position: 100% !important;
  -webkit-transition: .7s ease-in-out;
	transition: .7s ease-in-out;
}
.a-button:hover  {
  color:white !important;
}

.img-zoom-container {
  height: 44vh;
  overflow: hidden !important;
}
.img-zoom-container img {
  transition: transform .5s ease;
}
.img-zoom-container:hover img {
  transform: scale(1.3);
}

.display-7 {
  font-size:2.0rem;
}
.display-8 {
  font-size:1.5rem;
}